import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(app)/admin/mailtriggers": [5,[2]],
		"/(app)/admin/ordernumbers": [6,[2]],
		"/(app)/admin/saleschannels": [7,[2]],
		"/(app)/admin/settings": [8,[2]],
		"/(app)/admin/tools": [9,[2]],
		"/(app)/admin/userscripts": [11,[2]],
		"/(app)/admin/userscripts/create": [12,[2]],
		"/(app)/admin/userscripts/edit/[id]": [13,[2]],
		"/(app)/admin/users": [10,[2]],
		"/(app)/admin/warehouses": [14,[2]],
		"/(app)/admin/workerconsumers": [15,[2]],
		"/(app)/flaggedcustomers": [16,[2]],
		"/(app)/giftcards": [17,[2]],
		"/(order)/invoice/[orderNumber]": [47],
		"/(order)/joblists/[orderNumber]/[hathubReference]": [48],
		"/(auth)/login": [46],
		"/(app)/orders": [18,[2]],
		"/(app)/orders/big": [21,[2]],
		"/(app)/orders/paused": [22,[2]],
		"/(app)/orders/[orderId]": [19,[2,3]],
		"/(app)/orders/[orderId]/[tab]": [20,[2,3]],
		"/(app)/returnreasons": [23,[2]],
		"/(app)/scripting/playground": [24,[2]],
		"/(app)/shippingconfigs": [25,[2]],
		"/(app)/stats/picking": [26,[2]],
		"/(app)/store/create": [27,[2]],
		"/(app)/store/create/[storeDelivery]": [28,[2]],
		"/(app)/store/inventory": [29,[2]],
		"/(app)/store/production-orders": [30,[2]],
		"/(app)/store/receive": [31,[2]],
		"/(app)/store/register-giftcard": [32,[2]],
		"/(app)/store/sales": [33,[2]],
		"/(app)/store/send": [34,[2]],
		"/(app)/store/store-deliveries": [35,[2]],
		"/(app)/warehouse/consolidatedInvoices": [36,[2]],
		"/(app)/warehouse/designprintmanager": [37,[2]],
		"/(app)/warehouse/find-orders-by-sku": [38,[2]],
		"/(app)/warehouse/production": [39,[2]],
		"/(app)/warehouse/production/sheet/laser": [40,[2]],
		"/(app)/warehouse/production/sheet/pop": [41,[2]],
		"/(app)/warehouse/production/sheet/print": [42,[2]],
		"/(app)/warehouse/production/sheet/specific": [43,[2]],
		"/(app)/warehouse/vaspacker": [44,[2]],
		"/(app)/warehouse/vaspacker/[id]": [45,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';